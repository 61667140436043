import { css } from "styled-components"
import { responsiveHamburger } from "./responsiveHamburger"
import { colors, fontSizes } from "../../structure/theme"

export default css`
  color: ${colors(`grey.g800`)};
  padding: 0 8px;
  font-size: ${fontSizes(`small`)};
  justify-content: flex-start;
  font-weight: 700;

  ${responsiveHamburger`
    justify-content: space-between;
    font-weight: 700;
  `}

  &:before {
    display: none;
  }
`
