import React from "react"
import UniversePicto from "../../universe/universe.picto"
import styled from "styled-components"
import { responsiveHamburger } from "./responsiveHamburger"
import PropTypes from "prop-types"

export const PictoContainer = styled.span`
  margin-left: -10px;
  display: flex;

  &:first-of-type {
    margin-left: 0;
  }
`

export const HeaderPictos = styled.span`
  width: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${responsiveHamburger`
    display: none;
  `}
`

export default function MenuPictos ({ universes = [], color }) {
  if (universes.length === 0) {
    return null
  }

  return (
    <HeaderPictos data-testid="picto_menu_responsive">
      {universes.map(universe => (
        <PictoContainer key={universe.originalId}>
          <UniversePicto universe={universe} size={30} color={color} />
        </PictoContainer>
      ))}
    </HeaderPictos>
  )
}

MenuPictos.propTypes = {
  universes: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  color: PropTypes.string.isRequired,
}
