import React, { useMemo, useState, createContext, useContext } from "react"
import { MenuContainer, List, HeaderSidebar, FadeBackground, Nav } from "./styled"
import { useResponsiveMenu } from "./responsiveHamburger"
import MenuTab from "./menuTab.component"
import { graphql, useStaticQuery } from "gatsby"
import Hamburger from "./hamburger.component"
import Proptypes from "prop-types"
import Close from "../../../components/pictos/close.component"
import MenuItemEspaceClient from "./menuItemSelfcare.component"
import { usePageContext } from "../pageContext.provider"

const MENU_CLOSED = -1
const MENU_OPEN = 0

const MenuContext = createContext()

export function useMenu () {
  return useContext(MenuContext)
}

export default function Menu ({ sticky = false }) {
  const { locale } = usePageContext()
  const [activeMenuTab, setActiveMenuTab] = useState(MENU_CLOSED)
  const isResponsive = useResponsiveMenu()

  const {
    allMenu: { group },
  } = useStaticQuery(query)

  const groupIndex = useMemo(() => group.findIndex(({ nodes }) => nodes[0].locale === locale), [group, locale])
  const menuNodes = group[groupIndex] ? group[groupIndex].nodes : []

  const contextValue = useMemo(
    () => ({
      openMenu: () => {
        document.body.style.overflow = `hidden`
        setActiveMenuTab(MENU_OPEN)
      },
      closeMenu: () => {
        document.body.style.overflow = `auto`
        setActiveMenuTab(MENU_CLOSED)
      },
      closeTabs: () => {
        setActiveMenuTab(MENU_OPEN)
      },
      setActiveMenuTab,
      isActiveMenuTab: menuTabId => activeMenuTab === menuTabId,
      isResponsive,
    }),
    [activeMenuTab, isResponsive],
  )

  const { openMenu, closeMenu } = contextValue
  const isMenuOpen = activeMenuTab !== MENU_CLOSED
  const isMenuEmpty = menuNodes.length === 0 && !APP_CONFIG.featureFlags.selfcare

  return (
    <Nav>
      {!isMenuEmpty && <Hamburger toggle={openMenu} />}
      {isResponsive && isMenuOpen && <FadeBackground onClick={closeMenu} />}
      <MenuContainer open={isMenuOpen}>
        <HeaderSidebar>
          <Close onClick={closeMenu} />
        </HeaderSidebar>
        <MenuContext.Provider value={contextValue}>
          <List data-testid="menu">
            {menuNodes.map(menu => (
              <MenuTab key={menu.id} menu={menu} sticky={sticky} />
            ))}
            {APP_CONFIG.featureFlags.selfcare && <MenuItemEspaceClient />}
          </List>
        </MenuContext.Provider>
      </MenuContainer>
    </Nav>
  )
}

Menu.propTypes = {
  sticky: Proptypes.bool,
}

const query = graphql`
  query {
    allMenu(filter: { parent: { id: { eq: null } } }, sort: { fields: order, order: ASC }) {
      group(field: locale) {
        nodes {
          id
          locale
          name
          url
          universes {
            originalId
            primaryColor
            pictoSvg
            primaryColor
          }
          color
          children {
            ... on Menu {
              id
              name
              order
              url
              children {
                ... on Menu {
                  id
                  name
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
