import React from "react"
import Proptypes from "prop-types"
import styled, { css } from "styled-components"
import { responsiveHamburger } from "./responsiveHamburger"
import Link from "../link.component"
import { useMenu } from "./menu.component"
import { colors, condition, notCondition } from "../../structure/theme"

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const itemText = css`
  width: 100%;
  text-decoration: none;
  color: ${colors(`grey.g800`)};
`

const ItemLink = styled(Link)`
  ${itemText};

  &:hover {
    color: ${colors(`primary`)};
  }
`

const ItemText = styled.span`
  ${itemText};
`

const ItemList = styled.li`  
  display: flex;
  border-top: 1px solid ${colors(`grey.g500`)};

  ${condition(`category`)`
    text-transform: uppercase;
    background-color: ${colors(`grey.g300`)};
    color: ${colors(`grey.g800`)};

    ${ItemLink}, ${ItemText}  {
      margin: 8px 16px;
    }
  `}

  ${notCondition(`category`)`
    ${ItemLink}, ${ItemText} {
      margin: 5px 32px;
    }
  `}

  ${responsiveHamburger`
    border-top: 0;
    background-color: white;
    color: ${colors(`grey.g800`)};

    ${condition(`category`)`
      font-weight: 900;
    `}
   
    ${ItemLink}, ${ItemText} {
      margin: 5px 0;
    }
  `}
`

export default function MenuTabContent ({ menu }) {
  const { closeMenu } = useMenu()

  return (
    <List>
      <ItemList category data-testid="menu_subitem_universe">
        {!menu.url && <ItemText>{menu.name}</ItemText>}
        {menu.url && (
          <ItemLink onClick={closeMenu} href={menu.url} data-testid="menu_subitem_universe_link">
            {menu.name}
          </ItemLink>
        )}
      </ItemList>
      {menu.children.map(productMenu => (
        <ItemList key={productMenu.id} data-testid="menu_subitem_product" onClick={closeMenu}>
          <ItemLink href={productMenu.url} data-testid="menu_subitem_product_link">
            {productMenu.name}
          </ItemLink>
        </ItemList>
      ))}
    </List>
  )
}

MenuTabContent.propTypes = {
  menu: Proptypes.object.isRequired,
}
