import React from "react"
import Link from "../link.component"
import Logo from "../../branding/logo/logo.component"
import Menu from "../menu/menu.component"
import Proptypes from "prop-types"
import Selfcare from "../selfcare.component"
import { MenuWrapper } from "./menuWrapper.component"
import EscdaLazy from "../../landing/escda/escdaLazy.component"
import HeaderPhone from "../../coBrand/theme/headerPhone.component"
import { useTranslation } from "../../translation/translate.component"
import SwitchLang from "../../translation/switchLang.component"
import styled from "styled-components"
import { modules } from "../../structure/theme"
import breakpoint from "styled-components-breakpoint"
import { useUserContext } from "../user.provider"

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

const LogoCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${modules(`navigation.header.heightResponsive`)};
  width: 115px;
  position: relative;
  margin: 5px;

  ${breakpoint(`medium`)`
    margin: 0;
    
    ${APP_CONFIG.featureFlags.escda_header &&
      `
      width: 140px;
    `}
    
    ${!APP_CONFIG.featureFlags.escda_header &&
      `
      overflow-x: hidden;
      height: ${modules(`navigation.header.height`)};
      width: 200px;
    `}
  `}
`

const EscdaContainer = styled.div`
  display: none;

  ${breakpoint(`medium`)`
    display: block;
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
`

const MenuRight = styled.div`
  margin-left: auto;
  height: 100%;
  display: flex;
`

export default function PartnerHeader ({ isSticky, phoneNumber }) {
  const { isSimplifiedNavigation } = useUserContext()
  const { t } = useTranslation()

  return (
    <Container>
      <LogoCenter>
        <LogoContainer sticky={isSticky} data-testid="header_logo">
          <Link route="home" ariaLabel={t(`common:navigation.header_aria`)}>
            <Logo />
          </Link>
        </LogoContainer>
      </LogoCenter>
      {APP_CONFIG.featureFlags.escda_header && (
        <EscdaLazy Wrapper={EscdaContainer} data-testid="header_escda" withMargin={false}>
          *
        </EscdaLazy>
      )}
      <MenuWrapper>
        {!isSimplifiedNavigation && (
          <>
            <MenuRight>
              <Menu sticky={isSticky} />
            </MenuRight>
            {APP_CONFIG.featureFlags.selfcare && <Selfcare />}
          </>
        )}
        {isSimplifiedNavigation && (
          <MenuRight>
            <HeaderPhone phoneNumber={phoneNumber} />
          </MenuRight>
        )}
        <SwitchLang />
      </MenuWrapper>
    </Container>
  )
}

PartnerHeader.propTypes = {
  phoneNumber: Proptypes.string.isRequired,
  isSticky: Proptypes.bool.isRequired,
}
