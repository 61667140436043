import React, { useMemo } from "react"
import { Item, SubMenu, HamburgerCard, ItemTitle } from "./styled"
import MenuTabContent from "./menuTabContent.component"
import Proptypes from "prop-types"
import { Arrow } from "../../structure/theme/arrow.component"
import { useMenu } from "./menu.component"
import Link from "../link.component"
import styled from "styled-components"
import MenuPictos from "./menuPictos.component"

const StyledLink = styled(Link)`
  text-decoration: none;
`

export default function MenuTab ({ menu, sticky }) {
  const { isActiveMenuTab, setActiveMenuTab, closeMenu, closeTabs, isResponsive } = useMenu()
  const isActive = isActiveMenuTab(menu.id)

  const universesByOrder = useMemo(() => {
    const tabs = {}
    menu.children.forEach(child => {
      if (child.order in tabs) {
        tabs[child.order].push(child)
      } else {
        tabs[child.order] = [child]
      }
    })

    return tabs
  }, [menu.children])

  const [TitleWrapper, titleWrapperProps] = menu.url ? [StyledLink, { href: menu.url }] : [React.Fragment, null]

  return (
    <Item
      data-testid="menu_item"
      active={isActive && menu.children.length > 0}
      onMouseOver={() => !isResponsive && setActiveMenuTab(menu.id)}
      onMouseLeave={() => !isResponsive && closeMenu()}
    >
      <TitleWrapper {...titleWrapperProps}>
        <ItemTitle
          data-testid="menu_item_title"
          onClick={() =>
            menu.url ? closeMenu() : isResponsive && (isActive ? closeTabs() : setActiveMenuTab(menu.id))}
        >
          <MenuPictos universes={menu.universes} color={menu.color} />
          {menu.name}
          {` `}
          {menu.children.length > 0 && <Arrow isOpened={isActive} hideForMedium />}
        </ItemTitle>
      </TitleWrapper>
      {menu.children.length > 0 && (
        <SubMenu sticky={sticky} data-testid="submenu">
          {Object.keys(universesByOrder).map(tabKey => (
            <HamburgerCard key={tabKey}>
              {universesByOrder[tabKey].map(universe => (
                <MenuTabContent key={universe.id} menu={universe} />
              ))}
            </HamburgerCard>
          ))}
        </SubMenu>
      )}
    </Item>
  )
}

MenuTab.propTypes = {
  sticky: Proptypes.bool.isRequired,
  menu: Proptypes.object.isRequired,
}
