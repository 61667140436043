import React from "react"
import styled from "styled-components"
import loadable from "@loadable/component"
import { Button } from "../structure/theme/button.component"
import { useThemeContext } from "../structure/theme"
import { ModalButton } from "../../components/modal/modal.component"
import breakpoint from "styled-components-breakpoint"
import { responsiveHamburger } from "./menu/responsiveHamburger"
import ServiceClient from "../../components/pictos/serviceClient.component"
import { useTranslation } from "../translation/translate.component"

const SelfcareModal = loadable(() => import(`./selfcareModal.component`), { ssr: false })

const SelfCareButton = styled(Button).attrs({
  ghost: true,
})`
  order: -1;
  cursor: pointer;
  text-transform: none;
  padding: 0 9px;

  ${responsiveHamburger`
    margin-left: 10px;
    order: 1;
  `}
`

const Span = styled.span`
  display: none;

  ${breakpoint(`medium`)`
    margin-left: 10px;
    display: block;
  `}
`

export default function SelfCare () {
  const {
    colors: { primary },
  } = useThemeContext()
  const { t } = useTranslation()

  return (
    <ModalButton modalComponent={SelfcareModal}>
      {(onClick, ref) => (
        <SelfCareButton ref={ref} onClick={onClick} data-testid="selfcare_button">
          <ServiceClient size={20} color={primary} />
          <Span>{t(`common:navigation.menu_client_space`)}</Span>
        </SelfCareButton>
      )}
    </ModalButton>
  )
}
