import React from "react"
import styled from "styled-components"
import Proptypes from "prop-types"
import { responsiveHamburger } from "./responsiveHamburger"

const Bread = styled.div`
  width: 60px;
  height: 60px;
  position: relative;
  cursor: pointer;
  display: block;

  ${responsiveHamburger`
    display: none;
  `}
`

const Steak = styled.span`
  position: absolute;
  top: ${props => props.pos * 10 + 8}px;
  left: 15px;
  height: 2px;
  width: 30px;
  background-color: ${props => props.color || props.theme.colors.grey.g800};
`

export default function Hamburger ({ toggle }) {
  return (
    <Bread onClick={toggle} data-testid="menu_hamburger">
      <Steak pos={1} />
      <Steak pos={2} />
      <Steak pos={3} />
    </Bread>
  )
}

Hamburger.propTypes = {
  toggle: Proptypes.func.isRequired,
}
