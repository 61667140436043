import styled from "styled-components"
import { responsiveHamburger } from "./responsiveHamburger"
import ItemTitleCss from "./itemTitleCss"
import { fontSizes } from "../../structure/theme"

export const ItemTitle = styled.div`
  ${ItemTitleCss};
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: capitalize;
  position: relative;
  font-size: ${fontSizes(`small`)};

  ${responsiveHamburger`
    height: 100%;
    text-transform: uppercase;
  `}
`

export const HamburgerCard = styled.div`
  width: 100%;

  ${responsiveHamburger`
    width: ${100 / 3}%;
    padding: 10px;
  `}
`

export const HeaderSidebar = styled.div`
  padding: 5px 5px;
  position: absolute;
  left: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  display: none;

  ${responsiveHamburger`
    display: none;
  `}
`

export const MenuContainer = styled.nav`
  display: block;
  background-color: white;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
  width: 300px;
  transform: translateX(${props => (props.open ? 0 : `-100%`)});
  z-index: 1;

  ${props =>
    props.open &&
    `
    ${HeaderSidebar} {
      display: flex;
    }
  `}

  ${responsiveHamburger`
    position: static;
    transform: none;
    height: 100%;
    width: auto;
    box-shadow: none;
    background-color: none;
    overflow-y: visible;
  `}
`

export const Nav = styled.nav`
  ${responsiveHamburger`
    order: -1;
    height: 100%;
  `}
`

export const List = styled.ul`
  margin: 0 0 50px;
  padding: 0;
  list-style: none;
  height: auto;

  ${responsiveHamburger`
    display: flex;
    height: 100%;
  `}
`

export const SubMenu = styled.div`
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;

  ${responsiveHamburger`
    position: absolute;
    display: none;
    left: 0;
    right: 0;
    top: 100%;
    transition: background .3s;
    background-color: white;
    border-top: 3px solid ${props => props.theme.colors.primary};
  `}
`

export const Item = styled.li`
  border-bottom: 1px solid ${props => props.borderColor || props.theme.colors.grey.g500};
  cursor: pointer;

  ${props =>
    props.active &&
    `
  ${ItemTitle} {
      background-color: ${props.theme.colors.grey.g300};
    }

    ${SubMenu} {
      display: flex;
      flex-wrap: wrap;
    }
  `}

  ${responsiveHamburger`
    border-bottom: 0;
    display: flex;
    items-align: center;
    
    ${SubMenu} {
      display: none;
    }
    
    ${props =>
      props.active &&
      `
      box-shadow: inset 0 -5px 0 0 ${props.theme.colors.primary};
      
      ${ItemTitle} {
        background-color: none;
      }
      
      ${SubMenu} {
        display: flex;
        flex-wrap: wrap;
      }
    `}
    &:not(last-of-type):not(:first-of-type) ${ItemTitle}::before {
      content: ' ';
      position: absolute;
      left: -1px;
      top: 42%;
      width: 2px;
      height: 16%;
      background-color: ${props => props.theme.colors.primary};
      }
  `}
`

export const PictoWrapper = styled.span`
  margin-right: 10px;
`

export const FadeBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: block;
`
