import React from "react"
import PropTypes from "prop-types"
import Picto from "../picto.component"

export default function ServiceClient (props) {
  return (
    <Picto
      viewbox="0 0 26.42 36.09"
      d={[
        `M20.72,7.52a7.2,7.2,0,1,0-2.11,5.1A7.2,7.2,0,0,0,20.72,7.52Zm-7.21,5.3a5.3,5.3,0,1,1,5.3-5.3A5.29,5.29,0,0,1,13.51,12.82Z`,
        `M25.5,35.2a4.11,4.11,0,0,0,1.22-2.94V26.58a10.42,10.42,0,0,0-8-10.11l-.21,0a1,1,0,0,0-.75.36h0a5.28,5.28,0,0,1-7.41,1h0a5.19,5.19,0,0,1-1-1,1,1,0,0,0-.74-.36l-.21,0h0A10.24,10.24,0,0,0,.3,26.5v.07h0v5.69a4.17,4.17,0,0,0,4.16,4.16H22.57A4.08,4.08,0,0,0,25.5,35.2ZM2.21,32.26V26.58h0v0a8.35,8.35,0,0,1,5.56-7.86l.38-.13.29.28a7.21,7.21,0,0,0,5,2.06h.09a7.15,7.15,0,0,0,5-2.06l.29-.29.39.15a8.5,8.5,0,0,1,5.55,7.89h0v5.69a2.26,2.26,0,0,1-2.25,2.26H4.46A2.26,2.26,0,0,1,2.21,32.26Z`,
      ]}
      {...props}
    />
  )
}

ServiceClient.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
