import styled from "styled-components"
import { responsiveHamburger } from "./responsiveHamburger"
import { createSelfCareLink } from "../navigation.utils"
import { HeaderPictos } from "./menuPictos.component"
import ServiceClient from "../../../components/pictos/serviceClient.component"
import React from "react"
import { ItemTitle } from "./styled"
import { colors } from "../../structure/theme"
import { useTranslation } from "../../translation/translate.component"

const EspaceClientLi = styled.li`
  ${responsiveHamburger`
    display: none;
  `}
`

const EspaceClientTitle = styled(ItemTitle)`
  background-color: ${colors(`primary`)};
  color: white;
  justify-content: flex-start;
`

const PictoContainer = styled.span`
  background-color: white;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`

const EspaceClientLink = styled.a`
  text-decoration: none;
`

export default function MenuItemEspaceClient () {
  const { t } = useTranslation()

  return (
    <EspaceClientLi>
      <EspaceClientLink
        target="_blank"
        href={createSelfCareLink(`/?lien_interne=Header&_ga=2.203510608.1135567937.1580131443-1965172113.1579773205"`)}
        data-testid="selfcare_link_menu_responsive"
      >
        <EspaceClientTitle>
          <HeaderPictos>
            <PictoContainer>
              <ServiceClient size={20} color="red" />
            </PictoContainer>
          </HeaderPictos>
          {t(`common:navigation.menu_client_space`)}
        </EspaceClientTitle>
      </EspaceClientLink>
    </EspaceClientLi>
  )
}
